import Thread from "@/data/interface/Thread";

/**
 * Dummy data to populate user list in chats screen:
 */
export const Comments = <Thread[]>[
    {
        id: 1,
        content: "Impressive! Though it seems the drag feature could be improved. But overall it looks incredible. You've nailed the design and the responsiveness at various breakpoints works really well.",
        createdAt: "2022-08-15T12:00:00+08:00",
        score: 12,
        voting: 0,
        user: {
            id: '14a45d75-31fd-46b2-ab85-cc480eb3e05d',
            name: 'User B',
            phoneNumber: '012 3456 7890',
            avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
        },
        replies: []
    },
    {
        id: 2,
        content: "Woah, your project looks awesome! How long have you been coding for? I'm still new, but think I want to dive into React as well soon. Perhaps you can give me an insight on where I can learn React? Thanks!",
        createdAt: "2022-09-01T12:00:00+08:00",
        score: 5,
        voting: 0,
        user: {
            id: '14a45d75-31fd-46b2-ab85-cc480eb3e05d',
            name: 'User A',
            phoneNumber: '012 3456 7890',
            avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
        },
        replies: [
            {
                id: 3,
                content: "If you're still new, I'd recommend focusing on the fundamentals of HTML, CSS, and JS before considering React. It's very tempting to jump ahead but lay a solid foundation first.",
                createdAt: "2022-09-08T12:00:00+08:00",
                score: 4,
                voting: 0,
                replyingTo: "User B",
                user: {
                    id: '14a45d75-31fd-46b2-ab85-cc480eb3e05d',
                    name: 'User A',
                    phoneNumber: '012 3456 7890',
                    avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',

                },
            },
            {
                id: 4,
                content: "I couldn't agree more with this. Everything moves so fast and it always seems like everyone knows the newest library/framework. But the fundamentals are what stay constant. I couldn't agree more with this. Everything moves so fast and it always seems like everyone knows the newest library/framework. But the fundamentals are what stay constant.",
                createdAt: "2022-09-13T12:00:00+08:00",
                score: 2,
                voting: 0,
                replyingTo: "User B",
                user: {
                    id: '14a45d75-31fd-46b2-ab85-cc480eb3e05d',
                    name: 'User B',
                    phoneNumber: '012 3456 7890',
                    avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
                },
            }
        ]
    }
]
