

























































































































































































































































































































































import Vue from "vue";
import Thread from "@/data/interface/Thread";
import {Comments} from "@/data/mock/Comments";
import ChatDetail from "@/data/interface/ChatDetail";
import ChatSummary from '@/data/interface/ChatSummary';
import {ChatStatus} from "@/data/enum/ChatStatus";
import User from "@/data/interface/UserV2";
import axios from "axios";
import moment from "moment";
import CommunityHub from "@/data/interface/CommunityHub";
import CommunityHubGroup from "@/data/interface/CommunityHub";
import CommunityHubTopic from "@/data/interface/CommunityHub";
import CommunityHubEvent from "@/data/interface/CommunityHub";
import CommunityHubCourse from "@/data/interface/CommunityHub";

import Community from "@/data/interface/Community";
import {mapActions} from "vuex";
import VueRouter from 'vue-router';
import * as dotenv from 'dotenv';
import postCard from "../components/thread/PostCard.vue";
import CourseDirectory from "@/components/communityHub/CourseDirectory.vue";
import EventDirectory from "@/components/communityHub/EventDirectory.vue";
dotenv.config();

export default Vue.extend(
    {
      name      : 'Chats',
      components: {
        postCard,
        CourseDirectory,
        EventDirectory
      },

      data: () => ({
        selectedItem: 0,
        showTableOfContents: false,
        snackbar       : false,
        snackbarMessage: '',
        featureInfo: {
          type: Object
        },
        communityMembers: [] as User[],
        ably: null,
        channel: null,
        chatSummary: [] as ChatSummary[],
        chatDetails: [] as ChatDetail[],
        communityHub: [] as CommunityHub[],
        // used for sending message:
        dialog: false,
        archiveDialog: false,
        unarchiveText: '',
        revealedArchiveUserId: '',
        // used for showing success message:
        dialogBroadcast: false,

        // form fields and validation rules:
        valid  : false,
        message: '',
        featuresInChat: [{
        }],
        defaultUsers: ['@/assets/images/3x/user.png','@/assets/images/3x/user.png'],
        tab: null,
        itemsV2: [
          'All', 'Community', 'Groups', 'Topics',
        ],
        items: [
          { text: 'Threads', icon: 'mdi-forum' },
          { text: 'Courses', icon: 'mdi-certificate' },
          { text: 'Events', icon: 'mdi-ticket' }
        ],
        items2: [
          { title: 'Click Me' },
          { title: 'Click Me' },
          { title: 'Click Me' },
          { title: 'Click Me 2' },
        ],
      recent: [
        {
          active: true,
          avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
          title: 'Jason Oner',
        },
        {
          active: true,
          avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
          title: 'Mike Carlson',
        },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',
          title: 'Cindy Baker',
        },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/4.jpg',
          title: 'Ali Connors',
        },
      ],
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
      }),
      mounted: async function () {
          if (!(this as any).$route.params.id) {
            return;
          } else {
            (this as any).dialog = true
            axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
            axios.defaults.headers.common['x-functions-key'] = process.env.VUE_APP_CHEQQMATE_API_KEY || '';
            const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}communityHub?memberDirectoryId=${(this as any).$route.params.id}`);
            (this as any).communityHub = response.data as CommunityHub[];
            console.log((this as any).communityHub);
          }
      },
      watch: {
        // when members tab is clicked, fetch members:
        tab: {
          handler: function (newTab) {
            if (newTab === 3) {
              (this as any).fetchMembers();
            }
          }
        }
      },
      computed: {
        // get communityHub from vuex store:
        archivedChats() {
          let featureChats = (this as any).$store.state.communityHub;
          //find the featureChats that are not archived or rejected
          if(featureChats) {
            return featureChats.filter(featureChat => featureChat.communityHub?.find(chat => chat.status === ChatStatus.rejected || chat.status === ChatStatus.archived));
          }
          return featureChats;
        },
        activeChats() {
          let featureChats = (this as any).$store.state.communityHub;
          //find the featureChats that are not archived or rejected
          if(featureChats) {
            return featureChats.filter(featureChat => featureChat.communityHub?.find(chat => chat.status !== ChatStatus.rejected && chat.status !== ChatStatus.archived));
          }
          //sort the featureChats by last message datetime
          featureChats.sort((a, b) => {
            return new Date(b.communityHub[b.communityHub.length - 1].lastMessageDatetime).getTime() - new Date(a.communityHub[a.communityHub.length - 1].lastMessageDatetime).getTime();
          });
          return featureChats;
        },
        currentUser() {
          return (this as any).$store.state.currentUser;
        },
        comments() {
          return (this as any).$store.getters["thread/getAllComments"];
        },
        groups() : CommunityHubGroup[] {
          let groups = [];
          if ((this as any).communityHub && (this as any).communityHub.length > 0) {
            // Safely access groups using optional chaining
            groups = (this as any).communityHub[0]?.groups;
          }
          return groups;
        },
        topics() : CommunityHubTopic[] {
          var topics = [];
          if ((this as any).communityHub && (this as any).communityHub.length > 0) {
            // Safely access groups using optional chaining
            topics = (this as any).communityHub[0]?.topics;
          }
          return topics;
        },
        courses() : CommunityHubCourse[] {
          var courses = [];
          if ((this as any).communityHub && (this as any).communityHub.length > 0) {
            // Safely access groups using optional chaining
            courses = (this as any).communityHub[0]?.courses;
          }
          return courses;
        },
        events() : CommunityHubEvent[] {
          var events = [];
          if ((this as any).communityHub && (this as any).communityHub.length > 0) {
            // Safely access groups using optional chaining
            events = (this as any).communityHub[0]?.events;
          }
          return events;
        },
      },
      methods: {
        ...mapActions('realtime', ['instantiateAblyConnection']),

        //todo: remove this later
        async getUserInfo() {
          try {
            const response = await fetch('/.auth/me');
            const payload = await response.json();
            const { clientPrincipal } = payload;
            // console.log(clientPrincipal);
            return clientPrincipal;
          } catch (error) {
            console.error('No profile could be found');
            return undefined;
          }
        },
        timeAgo(partitionKey: string) {
          const chatSummary = (this as any).unreadChatSummary?(this as any).unreadChatSummary[0]?.find(summary => summary.partitionKey === partitionKey):null;
          return chatSummary?(this as any).getPrettyDate(chatSummary.lastMessageDatetime) : null;
        },
        async fetchMembers() {
          try {
            const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}memberDirectory?memberDirectoryId=${(this as any).$route.params.id}`);
            (this as any).communityMembers = response.data as User[];
            console.log((this as any).communityMembers);
          } catch (error) {
            console.error('No profile could be found');
          }
        },
        // count total number of unread message:
        countUnread(partitionKey: string) {
          const chatSummary = (this as any).unreadChatSummary?(this as any).unreadChatSummary[0]?.find(summary => summary.partitionKey === partitionKey):null;
          return chatSummary?chatSummary.unreadMessages : null;
        },
        // go to thread screen of selected thread:
        async toThreadScreen(id: any) {
          const thread: Thread[] = []
          //generate chats with random message, time and user:
          for (let i = 0; i < Comments.length; i++) {
            const newThread: Thread = {
              id: Comments[i].id,
              content: Comments[i].content,
              createdAt: Comments[i].createdAt,
              score: Comments[i].score,
              voting: Comments[i].voting,
              user: Comments[i].user,
              replies: Comments[i].replies
            };
            thread.push(newThread);
          }

          // console.log(thread);
          // store chats data in vuex store before navigating to chat screen:

          (this as any).$store.dispatch('thread/initComments', thread)
            .then(() => {
            (this as any).$router
              .push({
                path: '/home/hub/' + (this as any).$route.params.id + '/thread/' + id, 
                params: {
                  id: (this as any).$route.params.id,
                  threadId: id
                }
              }).catch(error => {
                if (VueRouter.isNavigationFailure(error, VueRouter.NavigationFailureType.redirected)) {
                  // ignore redirect error
                } else {
                  // handle other errors
                }
            });
          });
        },
        // go to event screen of selected event:
        async toEventScreen(feature: any) {
            (this as any).$router
              .push({
                path: '/home/hub/' + (this as any).$route.params.id + '/event/' + feature.id, 
                params: {
                  id: (this as any).$route.params.id,
                  eventId: feature.id
                }
              }).catch(error => {
                if (VueRouter.isNavigationFailure(error, VueRouter.NavigationFailureType.redirected)) {
                  // ignore redirect error
                } else {
                  // handle other errors
                }
            });
        },
        // go to event screen of selected event:
        async toCourseScreen(feature: any) {
            (this as any).$router
              .push({
                path: '/home/hub/' + (this as any).$route.params.id + '/course/' + feature.id, 
                params: {
                  id: (this as any).$route.params.id,
                  courseId: feature.id
                }
              }).catch(error => {
                if (VueRouter.isNavigationFailure(error, VueRouter.NavigationFailureType.redirected)) {
                  // ignore redirect error
                } else {
                  // handle other errors
                }
            })
        },
        // go to profile screen of selected event:
        async toProfileScreen(feature: any) {
            (this as any).$router
              .push({
                path: '/home/hub/' + (this as any).$route.params.id + '/profile/' + feature.id, 
                params: {
                  id: (this as any).$route.params.id,
                  profileId: feature.id
                }
              }).catch(error => {
                if (VueRouter.isNavigationFailure(error, VueRouter.NavigationFailureType.redirected)) {
                  // ignore redirect error
                } else {
                  // handle other errors
                }
            })
        },
        subtractDates(date) {
          const now = new Date();
          const diff = Math.abs(now.getTime() - new Date(date).getTime());
          const minutes = Math.floor((diff / 1000) / 60);
          return minutes;
        },
        generateChatSummary(chatSummary: ChatSummary[]) {
          // store communityHub data in vuex store:
          (this as any).$store.dispatch('chatSummary/setChatSummary', chatSummary)
        },
        generateGroupChats(community: Community[]) {
          // store communityHub data in vuex store:
          (this as any).$store.dispatch('communityHub/pushGroups', community)
              .then(() => {
                // navigate to home screen:
                // (this as any).$router.push({path: '/home/communityHub'});
                (this as any).$router.push('/home/communityHub').catch(error => {
                  if (VueRouter.isNavigationFailure(error, VueRouter.NavigationFailureType.redirected)) {
                    // ignore redirect error
                  } else {
                    // handle other errors
                  }
                });
              });
        },
        getPrettyDate(datetime:any) {
          // is the date within the last 24 hours? or within the last 7 days? or greater than 7 days? or previous year?
          const date = new Date(datetime);
          const now = new Date();
          const diff = Math.abs(now.getTime() - date.getTime());
          const minutes = Math.floor((diff / 1000) / 60);
          const hours = Math.floor(minutes / 60);
          const days = Math.floor(hours / 24);
          const year = date.getFullYear();
          const currentYear = now.getFullYear();
          // work out if date is today or yesterday:
          const isToday = date.toDateString() === now.toDateString();

          switch (true) {
            case hours < 24 && isToday:
              return moment(datetime).format('h:mm a');
            case days < 7:
              return moment(datetime).format('ddd, HH:mm');
            case year !== currentYear:
              return moment(datetime).format('DD/MM/YYYY');
            default:
              return moment(datetime).format('DD/MM');
          }
        },
        onClickOutsideTableOfContents () {
          (this as any).showTableOfContents = false
        },
      }
    }
  )
