import { Realtime } from 'ably/promises';
import Participants from '@/data/interface/Participants';

export default {
  namespaced: true,
  state: {
    ablyClientId: null,
    ablyChannelName: null,
    ablyRealtimeClient: null,
    participantMessage: null,
    lastReadMessage: [{
      messageId: null,
      clientId: null,
    }],
    connectionId: null,
    channelNames: {
      messaging: 'messaging',
    },
    channelInstances: {
      messaging: null,
    },
    isAblyConnected: false,
    participantsJoinedArr: [],
    participantsJoinedArrV2: [] as Participants[],
    participantsTyping: [{
      isTyping: false,
      clientId: null,
      datetime: null,
    }],
  },
  getters: {
    clientId: (state) => state.ablyClientId,
    channelName: (state) => state.ablyChannelName,
    haveParticipantsJoined: (state) => state.participantsJoinedArr.length > 1,
    haveParticipantsJoinedV2: (state) => state.participantsJoinedArrV2.length > 1,
    isAblyConnected: (state) => state.isAblyConnected,
    participantMessage: (state) => state.participantMessage,
    lastReadMessage: (state) => state.lastReadMessage,
    participantsJoined: (state) => state.participantsJoinedArrV2,
    numberOfParticipantsJoined: (state) => state.participantsJoinedArr.length,
    numberOfParticipantsJoinedV2: (state) => state.participantsJoinedArrV2.length,
    participantsTyping: (state) => state.participantsTyping.filter((participant) => participant.clientId),
  },
  mutations: {
    handleMessageReceived(state, message) {
      state.participantMessage = message;
    },
    removeParticipantMessage(state, { clientId, messageId }) {
      if (state.participantMessage) {
        state.participantMessage.splice(
          state.participantMessage.findIndex(
            (message) => message.id === messageId,
          ),
          1,
        );
      }
    },
    updateParticipantMessage(state, { clientId, messageId, reaction }) {
      if (state.participantMessage) {
        const message = state.participantMessage.find(
          (message) => message.id === messageId,
        );
        if (message) {
          message.reaction = reaction;
        }
      }
    },
    addParticipantMessage(state, { message }) {
        state.participantMessage = message;
    },
    addParticipantJoined(state, clientId: string) {
      if (!state.participantsJoinedArr.includes(clientId)) {
        state.participantsJoinedArr.push(clientId);
      }
    },
    addParticipantJoinedV2(state, clientId: string) {
      if (!state.participantsJoinedArrV2.find((participant) => participant.clientId === clientId)) {
        state.participantsJoinedArrV2.push({
          clientId,
          datetime: new Date().toISOString(),
        });
      }
    },
    removeParticipantJoined(state, clientId: string) {
      state.participantsJoinedArr.splice(
        state.participantsJoinedArr.findIndex(
          (participant) => participant.id === clientId,
        ),
        1,
      );
      state.participantsJoinedArrV2.splice(
        state.participantsJoinedArrV2.findIndex(
          (participant) => participant.clientId === clientId,
        ),
        1,
      );
    },
    setAblyChannelInstances(state, { messaging }) {
      state.channelInstances.messaging = messaging;
    },
    setAblyClientId(state, clientId: string) {
      state.ablyClientId = clientId;
    },
    setAblyChannelName(state, channelName: string) {
      state.ablyChannelName = channelName;
    },
    setAblyConnectionStatus(state, status: string) {
      state.isAblyConnected = status;
    },
    setAblyRealtimeClient(state, ablyRealtimeClient: string) {
      state.ablyRealtimeClient = ablyRealtimeClient;
    },
    setParticipantsTyping(state, { isTyping, clientId }) {
      const participant = state.participantsTyping.find(
        (participant) => participant.clientId === clientId,
      );
      if (participant) {
        participant.isTyping = isTyping;
        participant.datetime = new Date().toISOString();
      } else {
        state.participantsTyping.push({
          isTyping,
          clientId,
          datetime: new Date().toISOString(),
        });
      }
    },
    setLastReadMessage(state, message: any) {
      // message has a messageId and a clientId, replace the message with the same clientId
      // else add the message to the lastReadMessage array
      const index = state.lastReadMessage.findIndex(
        (msg) => msg.clientId === message.clientId,
      );
      if (index !== -1) {
        state.lastReadMessage.splice(index, 1, message);
      } else {
        state.lastReadMessage.push(message);
      }
    },
  },
  actions: {
    instantiateAblyConnection({
      dispatch, commit, state, getters,
    }, ids) {
      if (!getters.isAblyConnected) {
        const realtimeClient = new Realtime({
          authUrl: `${process.env.VUE_APP_CHEQQMATE_CHAT_API_BASE_URL}createTokenRequest?userId=${ids.clientId}`,
          authHeaders: {
            'x-functions-key': process.env.VUE_APP_CHEQQMATE_API_KEY || '',
            'Access-Control-Allow-Origin': process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '',
          },
          echoMessages: false,
        });

        realtimeClient.connection.on('connected', () => {
          commit('setAblyConnectionStatus', true);
          commit('setAblyRealtimeClient', realtimeClient);
          commit('setAblyClientId',ids.clientId ?? state.ablyRealtimeClient.auth.clientId);
          commit('setAblyChannelName',ids.sessionId);
    
          dispatch('attachToAblyChannels').then(() => {
            dispatch('enterClientInAblyPresenceSet');
            dispatch('getExistingAblyPresenceSet').then(() => {
              dispatch('subscribeToAblyPresence');
            });
          });
        });
    
        realtimeClient.connection.on('disconnected', () => {
          console.log('disconnected event trigger 1')
          commit('setAblyConnectionStatus', false);
        });
    
        realtimeClient.connection.on('closed', () => {
          console.log('closed event trigger')
          commit('setAblyConnectionStatus', false);
        });
      }
    },
    // instantiateAblyConnection({
    //   dispatch, commit, state, getters,
    // }, ids) {
    //   if (!getters.isAblyConnected) {
    //     const realtimeClient = new Realtime({
    //       authUrl: `${process.env.VUE_APP_API_BASE_URL}createTokenRequest?userId=${ids.clientId}`,
    //       echoMessages: false,
    //     });

    //     realtimeClient.connection.on('connected', () => {
    //       commit('setAblyConnectionStatus', true);
    //       commit('setAblyRealtimeClient', realtimeClient);
    //       commit('setAblyClientId',ids.clientId ?? state.ablyRealtimeClient.auth.clientId);
    //       commit('setAblyChannelName',ids.sessionId);

    //       dispatch('attachToAblyChannels').then(() => {
    //         dispatch('enterClientInAblyPresenceSet');
    //         dispatch('subscribeToAblyPresence');
    //         // dispatch('getExistingAblyPresenceSet').then(() => {
    //         //   dispatch('subscribeToAblyPresence');
    //         // });
    //       });
    //     });

    //     // Remove the 'disconnected' event listener
    //     realtimeClient.connection.off('disconnected', () => {
    //       realtimeClient.connection.close();
    //       console.log('disconnected event trigger 1')
    //       commit('setAblyConnectionStatus', false);
    //     });

    //     realtimeClient.connection.on('closed', () => {
    //       realtimeClient.connection.close();
    //       console.log('closed event trigger')
    //       commit('setAblyConnectionStatus', false);
    //     });
    //   }
    // },
    closeAblyConnection({ state }) {
      // console.log('closeAblyConnection', state.ablyRealtimeClient)
      if (state.ablyRealtimeClient) {
        state.ablyRealtimeClient.connection.close();
      }
    },
    sendMessageToAbly({ dispatch }, message) {
      dispatch('publishMessageToAbly', message);
    },
    sendReadReceiptToAbly({ dispatch }, messageId: string) {
      dispatch('publishReadReceiptToAbly', messageId);
    },
    async attachToAblyChannels({dispatch, commit, state,}) 
    {
      const channelName = `${state.channelNames.messaging}-${state.ablyChannelName}`;
      const messagingChannel = await state.ablyRealtimeClient.channels.get(
        channelName,
        // {
        //   params: { rewind: '2m' },
        // },
      );
      commit('setAblyChannelInstances', {
        messaging: messagingChannel,
      });
      dispatch('subscribeToAblyMessage');
    },
    /*
    presence.enter(data) - to add a user with a specific name
    presence.update - to update member data on a user which will publish a presence update
    presence.subscribe - to subscribe to updates on any member data on the channel
    */
    enterClientInAblyPresenceSet({ state }) {
      state.channelInstances.messaging.presence.enter({ // to add a user with a specific name
        id: state.ablyClientId,
      });
    },
    leaveClientInAblyPresenceSet({ state }) {
      state.channelInstances.messaging.presence.leave({ // to add a user with a specific name
        id: state.ablyClientId,
      });
    },
    updateUserTypingInAblyPresenceSet({ commit, state }) {
      console.log(new Date().toISOString(), 'updateUserTypingInAblyPresenceSet');
      if(state.channelInstances.messaging){
        state.channelInstances.messaging.presence.update({ // to update member data on a user which will publish a presence update
          id: state.ablyClientId,
          isTyping : true,
        });
        commit('setParticipantsTyping', {isTyping: true, clientId: state.ablyClientId});
      }

      // After a delay, assume the user stopped typing
      setTimeout(() => {
        if(state.channelInstances.messaging){
        state.channelInstances.messaging.presence.update({ // to update member data on a user which will publish a presence update
          id: state.ablyClientId,
          isTyping : false,
        });
        commit('setParticipantsTyping', {isTyping: false, clientId: state.ablyClientId});
      }}, 6000);

    },
    async getExistingAblyPresenceSet({ commit, state }) {
      await state.channelInstances.messaging.presence.get((err, participants) => {
        if (!err) {
          for (let i = 0; i < participants.length; i++) {
            // commit('addParticipantJoined', participants[i].clientId);
            commit('addParticipantJoinedV2', participants[i].clientId);
          }
        }
      });
    },
    subscribeToAblyPresence({ dispatch, state }) {
      /* Subscribe to presence enter events */
      state.channelInstances.messaging.presence.subscribe('enter', (msg) => {
        dispatch('handleNewParticipantEntered', msg);
        dispatch('handleNewParticipantEnteredV2', msg);
      });
      /* Subscribe to presence update events - MUST HAVE*/
      state.channelInstances.messaging.presence.subscribe('update', (msg) => {
        // console.log('ably presence update:', msg);
        dispatch('handleIsTypingMessageReceived', msg);
      });
      state.channelInstances.messaging.presence.subscribe('leave', (msg) => {
        dispatch('handleExistingParticipantLeft', msg);
      });
    },
    handleNewParticipantEntered({ commit }, participant: any) {
      commit('addParticipantJoined', participant.clientId);
    },
    handleNewParticipantEnteredV2({ commit }, participant: any) {
      commit('addParticipantJoinedV2', participant.clientId);
    },
    handleExistingParticipantLeft({ commit }, participant: any) {
      commit('removeParticipantJoined', participant.clientId);
    },
    subscribeToAblyMessage({ dispatch, state }) {
      state.channelInstances.messaging.subscribe('message', (msg) => {
        dispatch('handleMessageReceived', msg);
      });
      // allows us to know when a user is typing
      state.channelInstances.messaging.subscribe('isTypingMessage', (msg) => {
        dispatch('handleIsTypingMessageReceived', msg);
      });
      // Allows us to know when a user has read a message
      state.channelInstances.messaging.subscribe('read-receipt', (msg) => {
        dispatch('handleLastReadMessage', msg);
      });
    },
    handleVoteReceived({ commit }, msg) {
      commit('addParticipantVoted', {
        clientId: msg.data.clientId,
        cardNumber: msg.data.cardNumber,
      });
    },
    handleUndoVoteReceived({ commit }, msg) {
      commit('removeParticipantVoted', {
        clientId: msg.data.clientId,
        cardNumber: msg.data.cardNumber,
      });
    },
    handleShowResultsReceived({ commit }, msg) {
      if (msg.data.showResults) {
        commit('setShowResults', true);
      } else {
        commit('setShowResults', false);
      }
    },
    handleMessageReceived({ commit }, msg) {
      console.log('handleMessageReceived', msg);
      commit('addParticipantMessage', {
        message: msg.data,
      });
    },
    handleIsTypingMessageReceived({ commit }, msg) {
      commit('setParticipantsTyping', {
        isTyping: msg.data.isTyping,
        clientId: msg.data.id,
      });
    },
    handleLastReadMessage({ commit }, msg) {
      commit('setLastReadMessage', msg.data);
    },
    handleDeleteMessageReceived({ commit }, msg) {
      commit('removeParticipantMessage', {
        clientId: msg.data.userId,
        messageId: msg.data.messageId,
      });
    },
    handleReactToMessageReceived({ commit }, msg) {
      commit('updateParticipantMessage', {
        clientId: msg.data.userId,
        messageId: msg.data.messageId,
        reaction: msg.data.reaction,
      });
    },
    //messages
    publishMessageToAbly({ state }, clientMessage) {
      if(state.channelInstances.messaging){
        state.channelInstances.messaging.publish('message', clientMessage);
      }
    },
    publishResetMessageToAbly({ state }) {
      state.channelInstances.messaging.publish('reset-message', {});
    },
    //message read
    publishReadReceiptToAbly({ state }, messageId: string) {
      if(state.channelInstances.messaging){
        state.channelInstances.messaging.publish('read-receipt', messageId);
      }
    },
    //message interactions
    // sendMessageInteractionToAbly({ state }, { eventName, data, extras }) {
    //   state.channelInstances.messaging.publish({ 
    //     name: eventName, 
    //     data: data, 
    //     extras: extras 
    //   });
    // },    
  },
};

