import Thread from '@/data/interface/Thread';

const onVoting = ({ voting }, upvoted = false) => {
  switch (voting) {
    case 1:
      return upvoted ? -1 : -2;
    case -1:
      return upvoted ? 2 : 1;
    default:
      return upvoted ? 1 : -1;
  }
};

export default {
  namespaced: true,
  state: {
    comments: [],
    currentUser: [],
  },
  actions: {
    initComments({ commit }, payload: Thread[]) {
      commit('SET_COMMENTS', payload);
      // dispatch('loadAllComments');
    },
    loadAllComments: async ({ state, commit }) => {
      const comments = await JSON.parse(localStorage.getItem('comments') as any);

      if (comments) {
        commit('SET_COMMENTS', comments);
        return;
      }
      localStorage.setItem('comments', JSON.stringify(state.comments));
    },
    storeComments({ dispatch }, comment) {
      localStorage.setItem('comments', JSON.stringify(comment));
      dispatch('loadAllComments');
    },
    addComment: async ({ dispatch }, { commentId, comment }) => {
      const comments = await JSON.parse(localStorage.getItem('comments') as any);
      const id = comments.map(comment => comment.id);

      if (commentId) {
        const commentsIndex = comments.findIndex(comment => comment.id === commentId);
        const replies = comments[commentsIndex].replies;
        if (!replies.length) {
          comments[commentsIndex].replies.push({ id: 1, ...comment });
          dispatch('storeComments', comments);
          return;
        }
        let replyId = replies[replies.length - 1].id;
        comments[commentsIndex].replies.push({ id: (replyId += 1), ...comment });
        dispatch('storeComments', comments);
        return;
      }
      comments.push({ id: (id.length += 1), ...comment });
      dispatch('storeComments', comments);
    },
    updateComment: async ({ dispatch }, { commentId, replyId, editComment }) => {
      const comments = await JSON.parse(localStorage.getItem('comments') as any);
      const commentsIndex = comments.findIndex(comment => comment.id === commentId);
      if (replyId) {
        const repliesIndex = comments[commentsIndex].replies.findIndex(
          reply => reply.id === replyId
        );
        comments[commentsIndex].replies[repliesIndex].content = editComment;
        dispatch('storeComments', comments);
        return;
      }
      comments[commentsIndex].content = editComment;
      dispatch('storeComments', comments);
    },
    deleteComment: async ({ dispatch }, { commentId, replyId }) => {
      const comments = await JSON.parse(localStorage.getItem('comments') as any);
      const commentsIndex = comments.findIndex(comment => comment.id === commentId);
      if (replyId) {
        const repliesIndex = comments[commentsIndex].replies.findIndex(
          reply => reply.id === replyId
        );
        comments[commentsIndex].replies.splice(repliesIndex, 1);
        dispatch('storeComments', comments);
        return;
      }
      comments.splice(commentsIndex, 1);
      dispatch('storeComments', comments);
    },
    setVote: async ({ dispatch }, { commentId, replyId, upvoted = false }) => {
      const comments = await JSON.parse(localStorage.getItem('comments') as any);
      const comment = comments.find(comment => comment.id === commentId);

      if (replyId) {
        const replies = comment.replies.find(reply => reply.id === replyId);
        replies.score += onVoting(replies, upvoted);
        replies.voting += onVoting(replies, upvoted);
        dispatch('storeComments', comments);
        return;
      }
      comment.score += onVoting(comment, upvoted);
      comment.voting += onVoting(comment, upvoted);
      dispatch('storeComments', comments);
    },
  },
  getters : {
    getAllComments({ comments }) {
      return comments;
    },
    getCurrentUser({ currentUser }) {
      return currentUser;
    },
},  
  mutations: {
    SET_COMMENTS(state, payload: Thread) {
      console.log('payload', payload);
      state.comments = payload;
    },
  }
};

