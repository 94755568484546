import Vue from "vue";
import Vuex from "vuex";
import User from "@/data/interface/User";
import ChatSummary from "@/data/interface/ChatSummary";
import createPersistedState from 'vuex-persistedstate';
import UserModule from "@/store/modules/user";
import CurrentUserModule from "@/store/modules/currentUser";
import ChatsModule from "@/store/modules/chats";
import UpdatesModule from "@/store/modules/updates";
import ChatModule from "@/store/modules/chat";
import ThreadModule from "@/store/modules/thread";
import AuthenticationModule from "@/store/modules/authentication";
import RealtimeModule from "@/store/modules/realtime";
import lastSeenModule from "@/store/modules/lastSeen";
import ChatSummaryModule from "@/store/modules/chatSummary";
import thread from "@/store/modules/thread";
Vue.use(Vuex);

// vuex modules:
const modules = {
    user : UserModule,
    currentUser: CurrentUserModule,
    chats: ChatsModule,
    chatSummary: ChatSummaryModule,
    updates: UpdatesModule,
    chat : ChatModule,
    thread: ThreadModule,
    auth: AuthenticationModule,
    realtime: RealtimeModule,
    lastSeen: lastSeenModule
}

// selective persisted state:
const persistedState = createPersistedState(
    {
        paths: ['user', 'chats','lastSeen','updates','chatSummary','thread'] // chats is needed when refreshing the page to keep the chats history.
    });

export interface StateInterface {
    user: User;
    currentUser: User;
    chats: User[];
    chat: User;
    chatSummary: ChatSummary;
}

export default new Vuex.Store<StateInterface>(
    {
        modules: modules,

        actions: {
            // resets state of all the modules: this.$store.dispatch('reset');
            reset({commit}) {
                Object.keys(modules).forEach(moduleName => {
                    commit(`${moduleName}/RESET`);
                })
            }
        },

        plugins: [persistedState],
    })
