






























































































































































































































































































import Vue from "vue";
// import MyImage from "@/components/MyImage.vue";
import Chat from "@/data/interface/Chat";
import ChatDetail from "@/data/interface/ChatDetail";
import ChatSummary from '@/data/interface/ChatSummary';
import {ChatStatus} from "@/data/enum/ChatStatus";
import {ChatType} from "@/data/enum/ChatType";
import User from "@/data/interface/UserV2";
// import Notification from "@/data/interface/Notification";
import NotificationV2 from "@/data/interface/NotificationV2";
import axios from "axios";
import moment from "moment";
import UserV2 from "@/data/interface/UserV2";
import Community from "@/data/interface/Community";
import * as timeago from 'timeago.js';
import {mapActions} from "vuex";
import VueRouter from 'vue-router';
import * as Ably from 'ably';
import * as dotenv from 'dotenv';
dotenv.config();

export default Vue.extend(
    {
      name      : 'Chats',
      components: {},

      data: () => ({
        snackbar       : false,
        snackbarMessage: '',
        userInfo: {
          type: Object
        },
        ably: null,
        channel: null,
        chatSummary: [] as ChatSummary[],
        chatDetails: [] as ChatDetail[],
        // used for sending message:
        dialog: false,
        archiveDialog: false,
        unarchiveText: '',
        revealedArchiveUserId: '',
        // used for showing success message:
        dialogBroadcast: false,

        // form fields and validation rules:
        valid  : false,
        message: '',
        usersInChat: [{
        }],
        defaultUsers: ['@/assets/images/3x/user.png','@/assets/images/3x/user.png'],
        rulesMessage: [
          (value: string) => !!value || 'Message is required.',
          (value: string) => (value || '').length >= 1 || 'Message must be min 1 characters',
          (value: string) => (value || '').length <= 500 || 'Message can not be more than 500 characters',
        ],
      }),
      created() {
        this.fetchChat();
        this.fetchGroupChat();
      },
      //todo: remove this later
      // created() {
      //   console.log('Chats created');
      //   this.getUserInfo().then((userInfo) => {
      //     this.userInfo = userInfo;
      //     const user: UserV2 = {
      //         id         : this.uuidv4(),
      //         type       : 'user',
      //         name       : 'tzishi',
      //         avatar     : '@/assets/images/3x/user.png',
      //       }
      //       console.log(user);

      //       // generate random chats before updating sign up state:
      //       this.generateChats(user);          
      //     console.log(this.userInfo);
      //   });
      // },
      mounted() {
        // ably connection:

        (this as any).ably  = new Ably.Realtime("31XyRA.Ar9CWA:M3SVbwysMqqs47VTs80S3tt7iZkYAOI11lZH4J98GOY"); // may need to hardcode temporarily
        (this as any).channel = (this as any).ably.channels.get(this.currentUser.id);
        (this as any).channel.subscribe('alert', (message) => {
          console.log('Received message:', message.data);
          //updates chats and chatSummary
          // 1. chats updates the latest message on display
          (this as any).$store.dispatch('chats/updateChat', message.data);
          // 2. chatSummary updates the unread count
          console.log('chatSummary/updateChatSummaryIncrementally', message.data);
          (this as any).$store.dispatch('chatSummary/updateChatSummaryIncrementally', message.data)
        });
      },
      beforeDestroy() {
        // ably connection:
        if ((this as any).channel) {
          (this as any).channel.unsubscribe();
        }
        if ((this as any).ably) {
          (this as any).ably.close();
        }
      },
      computed: {
        // get chats from vuex store:
        archivedChats() {
          let userChats = this.$store.state.chats;
          //find the userChats that are not archived or rejected
          if(userChats) {
            return userChats.filter(userChat => userChat.chats?.find(chat => chat.status === ChatStatus.rejected || chat.status === ChatStatus.archived));
          }
          return userChats;
        },
        activeChats() {
          let userChats = this.$store.state.chats;
          //find the userChats that are not archived or rejected
          if(userChats) {
            return userChats.filter(userChat => userChat.chats?.find(chat => chat.status !== ChatStatus.rejected && chat.status !== ChatStatus.archived));
          }
          //sort the userChats by last message datetime
          userChats.sort((a, b) => {
            return new Date(b.chats[b.chats.length - 1].lastMessageDatetime).getTime() - new Date(a.chats[a.chats.length - 1].lastMessageDatetime).getTime();
          });
          return userChats;
        },
        currentUser() {
          return this.$store.state.currentUser;
        },
        lastSeen() {
          // let _lastSeen  = (this as any).$store.getters['lastSeen/getMostRecentlyLastSeen'];
          // return _lastSeen.filter((item: any) => item.clientId === this.currentUser.id)[0] as any;
          return (this as any).$store.getters['lastSeen/getMostRecentlyLastSeen'];
        },
        unreadChatSummary() {
          return (this as any).$store.getters['chatSummary/getChatUnread'];
        },
        // activeChats() {
        //   return this.$store.state.chats.filter(chat => chat.status !== ChatStatus.rejected || chat.status !== ChatStatus.archived);
        // },
      },
      methods: {
        ...mapActions('realtime', ['instantiateAblyConnection']),
        async fetchChat() {
          var userId = '';
          const adminUserLocalStorage = localStorage.getItem('user');
          const adminUserObject = adminUserLocalStorage ? JSON.parse(adminUserLocalStorage) : null;
          const adminUser = this.$store.state.auth.authUser?this.$store.state.auth.authUser:adminUserObject
          if(adminUser) {
            userId = adminUser.email as string;
          }else {
            const userInfo = await this.getUserInfo();
            userId = userInfo?userInfo.userId:'' as string;
          }
          // const currentUser: UserV2 = this.$store.state.currentUser;
          // convert objects under currentUser.subscriptions array into array of communityIds
          const communityIds = this.currentUser.subscriptions?.map(subscription => subscription.communityId) || [];
          axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
          axios.defaults.headers.common['x-functions-key'] = process.env.VUE_APP_CHEQQMATE_API_KEY || '';
          const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}chats?userId=${userId}&communityIds=${communityIds}&lastSeenDatetime=${(this as any).lastSeen?(this as any).lastSeen.datetime:''}`); 

          const userDetails = response.data.items;
          this.chatDetails = response.data.items;
          console.log('chatDetails & Summary: ', response.data);

          // store result in vuex store:
          this.generateChatSummary(response.data.chatSummary);          
          this.chatSummary = response.data.chatSummary;
          let archivedUser = {} as UserV2;
          for (const userDetail of userDetails) {
            if(userDetail.subType !== 'groupChat') {
              const users = await axios.get(`${process.env.VUE_APP_API_BASE_URL}user?id=${userDetail.userId}&partitionKey=${userDetail.userId=='Kingbot'?'google:Kingbot':userDetail.provider+':'+userDetail.userId}`);
              const pairedWithUsers = users.data;
              const user: UserV2 = {
                id          : pairedWithUsers.id,
                type        : pairedWithUsers.type,
                name        : pairedWithUsers.name,
                authProvider: pairedWithUsers.authProvider,
                partitionKey: userDetail.partitionKey,
                avatar      : pairedWithUsers.avatar,
                chats      : [{
                  id       : userDetail.chatId,
                  subType  : userDetail.subType,
                  message  : userDetail.message,
                  datetime : userDetail.datetime,
                  ago      : timeago.format(userDetail.datetime),
                  status : userDetail.userId !=='Kingbot' && userDetail?userDetail.status:null // Kingbot cannot be denied. hahaha //previously userDetail.status was userDetail.response
                }]
              };
              // archived user chats
              if(userDetail.status === ChatStatus.rejected) // if match is rejected.
                {
                  let targetUser = userDetail.matchNotification.participants.find(p => p.userId !== userDetail.partitionKey);
                  let currentUser = userDetail.matchNotification.participants.find(p => p.userId === userDetail.partitionKey);
                  archivedUser = {
                    id          : targetUser.userId,
                    type        : pairedWithUsers.type,
                    name        : userDetail.matchNotification.pairedWithName,
                    authProvider: targetUser.provider,
                    partitionKey: userDetail.matchNotification.chatPartitionKey,
                    avatar      : `https://api.dicebear.com/7.x/initials/svg?seed=${userDetail.matchNotification.pairedWithName}`,
                    chats      : [{
                      id       : userDetail.chatId, // this is the Kingbot chatId though
                      subType  : ChatType.privateChat,
                      message  : `${userDetail.matchNotification.userName} has rejected chat.😢`,
                      datetime : userDetail.matchNotification.matchResponseDate,
                      ago      : timeago.format(userDetail.matchNotification.matchResponseDate),
                      status   : currentUser.response //userDetail.matchNotification. needs to be status of participant so it doesn't affect targetUser experience
                    }]
                  }; 
                }       
                this.generateChats(user);
                if(archivedUser) {
                  this.generateArchivedChats(archivedUser);
                }
              }
            // else{ 
            //   //groupChat
            //   axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
            //   axios.defaults.headers.common['x-functions-key'] = process.env.VUE_APP_CHEQQMATE_API_KEY || '';
            //   const users = await axios.get(`${process.env.VUE_APP_API_BASE_URL}communityChats?communityId=${userDetail.partitionKey}`);
            //   const communityDetails = users.data;
            //   console.log('communityDetails: ', communityDetails)
            //   const groups: Community [] = [];
            //   for (const communityDetail of communityDetails) {
            //     const group: Community = {
            //     id          : communityDetail.id,
            //     type        : communityDetail.type,
            //     name        : communityDetail.name,
            //     description : communityDetail.description,
            //     avatars     : communityDetail.groupDetails.avatar, //delimited string of groups of avatars
            //     groupIds    : communityDetail.groupDetails.groupId, //delimited string of groups of ids
            //     groupNames  : communityDetail.groupDetails.groupName, //delimited string of groups of names
            //     chats      : [{
            //       id       : userDetail.chatId,
            //       subType  : userDetail.subType,
            //       message  : userDetail.message,
            //       datetime : userDetail.datetime,
            //       ago      : timeago.format(userDetail.datetime),
            //     }]
            //   };
            //   groups.push(group);
            //   }
            //   this.generateGroupChats(groups);             
            // }
          }
        },
        async fetchGroupChat() {
          var userId = '';
          const adminUserLocalStorage = localStorage.getItem('user');
          const adminUserObject = adminUserLocalStorage ? JSON.parse(adminUserLocalStorage) : null;
          const adminUser = this.$store.state.auth.authUser?this.$store.state.auth.authUser:adminUserObject
          if(adminUser) {
            userId = adminUser.email as string;
          }else {
            const userInfo = await this.getUserInfo();
            userId = userInfo?userInfo.userId:'' as string;
          }
          // const currentUser: UserV2 = this.$store.state.currentUser;
          // convert objects under currentUser.subscriptions array into array of communityIds
          const communityIds = this.currentUser.subscriptions?.map(subscription => subscription.communityId) || [];
          axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
          axios.defaults.headers.common['x-functions-key'] = process.env.VUE_APP_CHEQQMATE_API_KEY || '';
          const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}chats?userId=${userId}&communityIds=${communityIds}&lastSeenDatetime=${(this as any).lastSeen?(this as any).lastSeen.datetime:''}`); 

          const userDetails = response.data.items;
          this.chatDetails = response.data.items;
          console.log('chatDetails & Summary: ', response.data);

          for (const userDetail of userDetails) {
            if(userDetail.subType === 'groupChat')
              //groupChat
              axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
              axios.defaults.headers.common['x-functions-key'] = process.env.VUE_APP_CHEQQMATE_API_KEY || '';
              const users = await axios.get(`${process.env.VUE_APP_API_BASE_URL}communityChats?communityId=${userDetail.partitionKey}`);
              const communityDetails = users.data;
              console.log('communityDetails: ', communityDetails)
              const groups: Community [] = [];
              for (const communityDetail of communityDetails) {
                const group: Community = {
                id          : communityDetail.id,
                type        : communityDetail.type,
                name        : communityDetail.name,
                description : communityDetail.description,
                avatars     : communityDetail.groupDetails.avatar, //delimited string of groups of avatars
                groupIds    : communityDetail.groupDetails.groupId, //delimited string of groups of ids
                groupNames  : communityDetail.groupDetails.groupName, //delimited string of groups of names
                chats      : [{
                  id       : userDetail.chatId,
                  subType  : userDetail.subType,
                  message  : userDetail.message,
                  datetime : userDetail.datetime,
                  ago      : timeago.format(userDetail.datetime),
                }]
              };
              groups.push(group);
              }
              this.generateGroupChats(groups);
          }            
        },
        //todo: remove this later
        async getUserInfo() {
          try {
            const response = await fetch('/.auth/me');
            const payload = await response.json();
            const { clientPrincipal } = payload;
            // console.log(clientPrincipal);
            return clientPrincipal;
          } catch (error) {
            console.error('No profile could be found');
            return undefined;
          }
        },
        timeAgo(partitionKey: string) {
          const chatSummary = (this as any).unreadChatSummary?(this as any).unreadChatSummary[0]?.find(summary => summary.partitionKey === partitionKey):null;
          return chatSummary?(this as any).getPrettyDate(chatSummary.lastMessageDatetime) : null;
        },
        // count total number of unread message:
        countUnread(partitionKey: string) {
          const chatSummary = (this as any).unreadChatSummary?(this as any).unreadChatSummary[0]?.find(summary => summary.partitionKey === partitionKey):null;
          return chatSummary?chatSummary.unreadMessages : null;
        },
        // go to chat screen of target user:
        async toChatScreen(user: User) {
          if(user.type === 'user'){
            // add authenticated user to a group for real time messaging:
            // if the user was last seen less than 50 minutes ago, add them to the group
            const targetUser =  this.$store.state.chats?.find(chat => chat.id === user.id) as User;

            // filter chatSummary to get the chatSummary of the target user partitionKey
            const targetUserChatSummary = this.chatSummary?.find(chatSummary => chatSummary.partitionKey === targetUser.partitionKey);
            const maxChats = targetUserChatSummary?.maxChats?? 10;
            // navigate to chat screen and onMount store chats data in vuex store

            // initialize Ably
            if(targetUser.partitionKey !== undefined && this.$store.state.currentUser.id !== undefined){
              this.instantiateAblyConnection({
                sessionId: targetUser.partitionKey,
                clientId: this.$store.state.currentUser.id,
              })
            }

            this.$router
              .push({
                path: '/home/chats/' + user.id + '/count/' + maxChats, 
                params: {
                  id: user.id
                }
              }).catch(error => {
                if (VueRouter.isNavigationFailure(error, VueRouter.NavigationFailureType.redirected)) {
                  // ignore redirect error
                } else {
                  // handle other errors
                }
            });
          } 
          else {
            // navigate to community hub screen
            this.$router
              .push({
                path: '/home/hub/' + user.id, 
                params: {
                  id: user.id
                }
              });
          }
        },

        broadcastMessage() {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore:

          // once form is valid: continue broadcasting message:
          if (this.$refs.form.validate()) {
            // get current authenticated user:
            const user: User = this.$store.state.user;

            // create chat object:
            const chat: Chat = {
              id       : this.uuidv4(),
              message  : this.message,
              timestamp: moment().format('h:mm a'),
              status   : ChatStatus.sent,
              user     : user,
            };
            const notificationV2: NotificationV2 = {
              notification: {
                title   : user.name + ' sent you a message',
                body    : this.message,
                sound   : true,
                priority: 'high',
              }
            };
            // hide dialog and reset message text input:
            this.dialog  = false;
            this.message = '';

            // send message via firebase topic, becasuse this app doesn't have backend and database:
            // const url = 'https://fcm.googleapis.com/v1/projects/cheqqmate/messages:send'
            axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
            axios.defaults.headers.common['x-functions-key'] = process.env.VUE_APP_CHEQQMATE_API_KEY || '';
            axios.post(`${process.env.VUE_APP_API_BASE_URL}messages`, {
              // await axios.post('${process.env.VUE_APP_API_BASE_URL}messages', {
              text: notificationV2
            })
          }
        },
        subtractDates(date) {
          const now = new Date();
          const diff = Math.abs(now.getTime() - new Date(date).getTime());
          const minutes = Math.floor((diff / 1000) / 60);
          return minutes;
        },
        // generate random uuid:
        uuidv4() {
          return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
          });
        },
        generateArchivedChats(user: UserV2){
          this.$store.dispatch('chats/pushUsers', user)
              .then(() => {
                this.$store.dispatch('user/updateUser', user)
              });
          console.log('generateArchivedChats: ', user);
          // this.$store.dispatch('chats/pushArchivedUsers', user)
        },
        // generate initial conversation of 100 per user:
        //change user: to list of User
        generateChats(user: UserV2) {

          // update chat data in vuex store:
          const payload: any = user.chats?user.chats[0]:'';
          payload.partitionKey = user.partitionKey;
          (this as any).$store.dispatch('chats/updateChat', payload);

          // store chats data in vuex store:
          // this.$store.dispatch('chats/initUsers', user)
          this.$store.dispatch('chats/pushUsers', user)
              .then(() => {

                // store user data in vuex store:
                // console.log('user object being updated: ', user);

                this.$store.dispatch('user/updateUser', user)
                    .then(() => {
                      // navigate to home screen:
                      // this.$router.push({path: '/home/chats'});
                      this.$router.push('/home/chats').catch(error => {
                        if (VueRouter.isNavigationFailure(error, VueRouter.NavigationFailureType.redirected)) {
                          // ignore redirect error
                        } else {
                          // handle other errors
                        }
                      });
                      // console.log('generateChats: ', this.$store.state.chats);
                    });
              });
        },
        generateChatSummary(chatSummary: ChatSummary[]) {
          // store chats data in vuex store:
          this.$store.dispatch('chatSummary/setChatSummary', chatSummary)
        },
        unarchive(item: UserV2) {
          this.unarchiveText = 'confirm';
          console.log('unarchive: ', item);
        },
        confirmUnarchive(item: UserV2) {
          console.log('confirmUnarchive: ', item);
          this.archiveDialog = false;
        },
        generateGroupChats(community: Community[]) {
          // store chats data in vuex store:
          this.$store.dispatch('chats/pushGroups', community)
              .then(() => {
                // navigate to home screen:
                // this.$router.push({path: '/home/chats'});
                this.$router.push('/home/chats').catch(error => {
                  if (VueRouter.isNavigationFailure(error, VueRouter.NavigationFailureType.redirected)) {
                    // ignore redirect error
                  } else {
                    // handle other errors
                  }
                });
              });
        },
        getPrettyDate(datetime:any) {
          // is the date within the last 24 hours? or within the last 7 days? or greater than 7 days? or previous year?
          const date = new Date(datetime);
          const now = new Date();
          const diff = Math.abs(now.getTime() - date.getTime());
          const minutes = Math.floor((diff / 1000) / 60);
          const hours = Math.floor(minutes / 60);
          const days = Math.floor(hours / 24);
          const year = date.getFullYear();
          const currentYear = now.getFullYear();
          // work out if date is today or yesterday:
          const isToday = date.toDateString() === now.toDateString();

          switch (true) {
            case hours < 24 && isToday:
              return moment(datetime).format('h:mm a');
            case days < 7:
              return moment(datetime).format('ddd, HH:mm');
            case year !== currentYear:
              return moment(datetime).format('DD/MM/YYYY');
            default:
              return moment(datetime).format('DD/MM');
          }
        },
      }
    })
