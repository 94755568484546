<!-- <template>
  <div style="background-color: #f6f9fc;height: 100vh; margin: 0; padding: 0;">
    <v-btn class="ml-12 mt-2 mb-n2" large icon :href='`/home/hub/${this.$route.params.id}`'>
        <v-icon>mdi-chevron-left</v-icon>
    </v-btn>
    <v-card
        class="pl-12 pr-12 pb-6"
        flat
        style="background-color: #f6f9fc;"
    >
    <v-img
      height="250"
      class="ma-4"
      src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
    ></v-img>
    <v-card-title class="text-center justify-start py-6">
        <h1 class="font-weight-bold text-h2 basil--text">
            BASiL
        </h1>
    </v-card-title>
    <v-card-text>
      <v-row
        align="center"
        class="mx-0"
      >
        <v-rating
          :value="4.5"
          color="amber"
          dense
          half-increments
          readonly
          size="14"
        ></v-rating>

        <div class="grey--text ms-4">
          4.5 (413)
        </div>
      </v-row>

      <div class="my-4 text-subtitle-1">
        $ • Italian, Cafe
      </div>

      <div>Small plates, salads & sandwiches - an intimate setting with 12 indoor seats plus patio seating.</div>
    </v-card-text>

    <v-divider class="mx-4"></v-divider>

    <v-card-title>Tonight's availability</v-card-title>

    <v-card-text>
      <v-chip-group
        v-model="selection"
        active-class="deep-purple accent-4 white--text"
        column
      >
        <v-chip>5:30PM</v-chip>

        <v-chip>7:30PM</v-chip>

        <v-chip>8:00PM</v-chip>

        <v-chip>9:00PM</v-chip>
      </v-chip-group>
    </v-card-text>

    <v-card-actions>
      <v-btn
        
        text
        @click="reserve"
      >
        Reserve
      </v-btn>
    </v-card-actions>
    </v-card>
    </div>
  </template> -->
  <template>
    <div style="background-color: #f6f9fc; height: 100vh; margin: 0; padding: 0;">
        <!---table of contents-->
        <v-card flat style="height: 100px; z-index: 1;">
        <v-toolbar
            color="primary"
            dark
            extended
            flat
        >
        <v-btn class="mt-2 mb-2" large icon :href='`/home/hub/${this.$route.params.id}`'>
            <v-icon>mdi-chevron-left</v-icon>
        </v-btn> 
        <v-app-bar-nav-icon class="ml-4">
            <v-icon>mdi-ticket</v-icon>
        </v-app-bar-nav-icon>
        <v-list-item-title class="text-h6">
            Event
        </v-list-item-title>
        <div class="svg-border-waves grey--text text--darken-1">
            <v-img src="@/assets/borderWaves.svg" />
        </div>
        </v-toolbar>
        
          <!-- <v-card
              v-click-outside="onClickOutsideTableOfContents"
              class="mx-auto mt-n8"
              max-width="700"
              tile
              flat
          >
              <v-toolbar flat>
              <v-btn class="mt-2 mb-2" large icon :href='`/home/hub/${this.$route.params.id}`'>
                  <v-icon>mdi-chevron-left</v-icon>
              </v-btn>   
              <v-toolbar-title class="grey--text">
                  Course Title
              </v-toolbar-title>
      
              <v-spacer></v-spacer>
              <v-btn large icon @click="showTableOfContents = !showTableOfContents">
                  <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
              </v-toolbar>
      
              <v-divider></v-divider>
              <v-card-text v-if="showTableOfContents" style="height: 200px; z-index: 1;">
              <v-list-item-title class="mb-1">
                  Table of Contents
              </v-list-item-title>
              
              </v-card-text>
          </v-card> -->
        </v-card>
        <!--content-->
        <v-card
            outlined
            class="ma-16 pa-6"
            flat
        >
            <v-img
                height="250"
                class="ma-4"
                src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
            ></v-img>
            <v-card-title class="text-center justify-start py-6">
                <h1 class="font-weight-bold text-h2 basil--text
                ">
                    BASiL
                </h1>
            </v-card-title> 
            <!---hosted by-->
            <v-avatar
                size="48"
                class="ml-4"
            >
                <v-img
                    src="https://cdn.vuetifyjs.com/images/john.jpg"
                ></v-img>
            </v-avatar>
            <span class="ml-2"> Hosted by John</span>
            <v-card-text>
      <v-row
        align="center"
        class="mx-0"
      >
        <v-rating
          :value="4.5"
          color="amber"
          dense
          half-increments
          readonly
          size="14"
        ></v-rating>

        <div class="grey--text ms-4">
          4.5 (413)
        </div>
      </v-row>

      <div class="my-4 text-subtitle-1">
        $ • Italian, Cafe
      </div>

      <div>Small plates, salads & sandwiches - an intimate setting with 12 indoor seats plus patio seating.</div>
    </v-card-text>

    <v-divider class="mx-4"></v-divider>

    <v-card-title>Tonight's availability</v-card-title>

    <v-card-text>
      <v-chip-group
        v-model="selection"
        active-class="deep-purple accent-4 white--text"
        column
      >
        <v-chip>5:30PM</v-chip>

        <v-chip>7:30PM</v-chip>

        <v-chip>8:00PM</v-chip>

        <v-chip>9:00PM</v-chip>
      </v-chip-group>
    </v-card-text>

    <v-card-actions>
      <v-btn
        class="font-weight-bold text-h6 text-none"
        color="primary"
        x-large
        elevation="0"
        @click="reserve"
      >
        Attend
      </v-btn>
    </v-card-actions>
        </v-card>
    </div>
  </template>
  <script>
    export default {
      data () {
        return {
          selection: [],
          showTableOfContents: false,
        }
      },
      methods: {
        onClickOutsideTableOfContents () {
          this.showTableOfContents = false
        },
      }
    }
</script>
<style>
/* Helper classes */
.basil {
  background-color: #FFFBE6 !important;
}
.basil--text {
  color: #356859 !important;
}
</style>