import { render, staticRenderFns } from "./Thread.vue?vue&type=template&id=03772f78&scoped=true&"
import script from "./Thread.vue?vue&type=script&lang=ts&"
export * from "./Thread.vue?vue&type=script&lang=ts&"
import style1 from "./Thread.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03772f78",
  null
  
)

export default component.exports
import QInfiniteScroll from 'quasar/src/components/infinite-scroll/QInfiniteScroll.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QInfiniteScroll});


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
installComponents(component, {VBottomSheet,VCard,VCardSubtitle,VCardText,VCardTitle,VDivider})
