











import Vue from "vue";
import axios from "axios";
import Chat from "@/data/interface/Chat";
import User from "@/data/interface/User";
// import { mapActions } from "vuex";
const vapidKey = process.env.VUE_APP_FIREBASE_VAPID_KEY

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// import firebaseInitialize from './firebase';

export default Vue.extend(
    {
      name: 'App',
      mounted() {
        // request notification permission:
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.$messaging
        .requestPermission()
            .then(() => {
              console.log('Notification permission granted.');
              // get token:
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              this.$messaging
                  .getToken({vapidKey: vapidKey})
                  .then((token: any) => {
                    // token generated:
                    if (token) {  
                      console.log('Token generated:', token);
                      
                      // subscribe to topic. This is important to receive notification as this app doesn't connect to any backends.
                      // too little time, remove server key and delete project for security reason.


                      //I don't need topics, I need to send to specific user

                      // axios
                      //     .post('https://iid.googleapis.com/iid/v1/' + token + '/rel/topics/all',
                      //           {},
                      //           // {
                      //           //   headers: {
                      //           //     'Authorization': 'key=AAAA_ZYyd8Y:APA91bG-SxCRrrCV59b8NtqWkOcKbYJq3SGiD3oTQdgP-LixTj3deo-2WHIcnAhSm00Gs1MEod8IvTUwawhhoeBt3ZWGXKHE78_V97C65NLTnQHEFb6cBG6JyOXVebfLOY2TxJ1AClPi'
                      //           //   }
                      //           // },
                      //     )
                      //     .then(response => {
                      //       console.log('Successfully subscribed to topic:', response);
                      //     })
                      //     .catch((error: any) => {
                      //       // show alert if subscription fails:
                      //       console.log('Error subscribing to topic:', error);
                      //       alert('Firebase configuration failed, live chat will not work.');
                      //     })

                      // push token to store:
                      this.$store.dispatch('user/updateUserToken', token);

                    } else {
                      // show alert if token generation fails:
                      // console.log('No registration token available. Request permission to generate one.');
                      // alert('No registration token available. Request permission to generate one.');
                    }
                  })
                  .catch((err: any) => {
                    // show alert if token generation fails:
                    // console.log('An error occurred while retrieving token. ', err);
                    // alert('Firebase configuration failed, live chat will not work.');
                  });
              // on foreground notification message:
              // store in store: either as 1. as new thread, 2 push to chat stream:
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore             
              this.$messaging
                  .onMessage((payload: any) => {
                    console.log('Receiving foreground message: ', {payload});

                    if (payload.data?.to && payload.data.chat) {
                      const to: string = payload.data.to;
                      const chat: Chat = JSON.parse(payload.data.chat);

                      // get current authenticated user id:
                      const user: User = this.$store.state.user;

                      const from: string | undefined = chat.user?.id;

                      console.log('Receiving foreground message: ', {to, from, user, chat});

                      // stop processing if current user broadcasted/sent message.
                      if (from && from === user.id) {
                        return;
                      }

                      if (to === 'all') {
                        // if it was broadcast message
                        // and existing thread with target user doesn't exists in store,
                        // create new chat thread in chats screen.
                        // otherwise insert into existing thread.

                        // call store action:

                        /* TRZ - 05.12.23
                        this.$store.dispatch('chats/addUser', {id: from, chat: chat});
                        */

                      } else if (to === 'updateStatus') {
                        // if requested to update read status of messages:
                        this.$store.dispatch('chat/setRead', from);

                      } 
                      /*
                        else if (to.length) {
                        // if it is 1 to 1 chat, insert into chat stream:
                        this.$store.dispatch('chats/addUser', {id: to, chat: chat})
                            .then(() => {
                              // update chat module too.
                              // as chat module can get too large 5000+ chats, had to se 2 module, 1 for users 1 for chats.
                              // find solution to improve this part and use 1 state rather than 2.
                              this.$store.dispatch('chat/pushThreadChat', chat);
                            });
                      }
                      */
                    }
                  });
            })
            .catch((err: any) => {
              //TODO: show alert if permission not granted:
              // console.log('Unable to get permission to notify.', err);
              // alert('Permission not granted, live chat will not work.\nPlease enable it from site settings.');
            });
        // add refresh token listener:
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // Handle token refresh
        this.$messaging.onTokenRefresh(() => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.$messaging.getToken({vapidKey: vapidKey})
            .then((refreshedToken) => {
              console.log('Token refreshed.', refreshedToken);
              // Indicate token refresh to your server if needed
              // For example, you can dispatch an action to update the user token in your store
              this.$store.dispatch('updateUserToken', refreshedToken);
            })
            .catch((err) => {
              console.log('Unable to retrieve refreshed token ', err);
            });
        });
        // fetch user info if not stored in store:
        // that means user is not logged in already and prevent multiple fetches:
        // and url is home page:
        if (!this.$store.state.currentUser.id) {
          // parse user info from local storage:
          const adminUserLocalStorage = localStorage.getItem('user');
          const adminUserObject = adminUserLocalStorage ? JSON.parse(adminUserLocalStorage) : null;
          const adminUser = this.$store.state.auth.authUser?this.$store.state.auth.authUser:adminUserObject
          if(adminUser) {
            this.fetchAdminUser();
          } else {
            this.fetchUser();
          }
        }
      },

      methods: {
        // get user info from vuex store:
        async getUserInfo() {
          try {
            const response = await fetch('/.auth/me');
            const payload = await response.json();
            const { clientPrincipal } = payload;
            // console.log(clientPrincipal);
            return clientPrincipal;
          } catch (error) {
            console.error('No profile could be found');
            return undefined;
          }
        },     
        // fetch user info from cosmos db:
        fetchUser(){
          this.getUserInfo().then((userInfo) => {
            if(userInfo) {
              const id = userInfo?userInfo.userId:'';
              const partitionKey = userInfo?userInfo.identityProvider+':'+userInfo.userId:'';
              axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
              axios.defaults.headers.common['x-functions-key'] = process.env.VUE_APP_CHEQQMATE_API_KEY || '';
              axios.get(`${process.env.VUE_APP_API_BASE_URL}user?id=${id}&partitionKey=${partitionKey}&type=currentUser`)
                
                .then(response => {
                  // update user info in store:;
                  this.$store.dispatch('currentUser/updateCurrentUser', response.data)
                })
                .catch(error => {
                  console.error(error??'Error fetching user info');
                });
            }
          });
        },
        fetchAdminUser(){
          const adminUserLocalStorage = localStorage.getItem('user');
          const adminUserObject = adminUserLocalStorage ? JSON.parse(adminUserLocalStorage) : null;
          const adminUser = this.$store.state.auth.authUser?this.$store.state.auth.authUser:adminUserObject
          const id = adminUser.email;
          const partitionKey = 'email'+':'+adminUser.email;
          //add the token in the header
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + adminUser.stsTokenManager.accessToken;
          axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
          axios.defaults.headers.common['x-functions-key'] = process.env.VUE_APP_CHEQQMATE_API_KEY || '';
          axios.get(`${process.env.VUE_APP_API_BASE_URL}user?id=${id}&partitionKey=${partitionKey}&type=currentUser`)
            .then(response => {
              // update user info in store:;
              this.$store.dispatch('currentUser/updateCurrentUser', response.data)
            })
            .catch(error => {
              console.error(error??'Error fetching user info');
            });
        }
      }
  });
