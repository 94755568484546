<template>
    <div style="background-color: #f6f9fc; height: 100vh; margin: 0; padding: 0;">
        <!---table of contents-->
        <v-card flat style="height: 100px; z-index: 1;">
        <v-toolbar
            color="primary"
            dark
            extended
            flat
        >
        <v-btn class="mt-2 mb-2" large icon :href='`/home/hub/${this.$route.params.id}`'>
            <v-icon>mdi-chevron-left</v-icon>
        </v-btn> 
        <v-app-bar-nav-icon class="ml-4">
            <v-icon>mdi-certificate</v-icon>
        </v-app-bar-nav-icon>
        <v-list-item-title class="text-h6 mb-1">
            Course
        </v-list-item-title>
        <div class="svg-border-waves grey--text text--darken-1">
            <v-img src="@/assets/borderWaves.svg" />
        </div>
        <v-spacer></v-spacer>
        <v-btn
          class="text-none text-bold mr-2"
          text
          icon
          @click = "showTableOfContents = true"
        >
        <v-icon large>
          mdi-table-of-contents
        </v-icon>
        </v-btn>
        </v-toolbar>
        
          <!-- <v-card
              v-click-outside="onClickOutsideTableOfContents"
              class="mx-auto mt-n8 border-radius"
              max-width="700"
              flat
          >
              <v-toolbar flat class="">
              <v-toolbar-title class="text-h5 text-center grey--text">
                  Table of Contents
              </v-toolbar-title>
      
              <v-spacer></v-spacer>
              <v-btn large icon @click="showTableOfContents = !showTableOfContents">
                  <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
              </v-toolbar>
      
              
              <v-card-text v-if="showTableOfContents" style="height: 200px; z-index: 1;" class="mt-n4">
                <v-divider></v-divider>
              <v-list-item-title class="mt-2">
                  Table of Contents
              </v-list-item-title>
              
              </v-card-text>
          </v-card> -->
        </v-card>
        <!--content-->
        <v-card
            outlined
            class="ma-16 pa-6"
            flat
        >
            <v-img
                height="250"
                class="ma-4"
                src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
            ></v-img>
            <v-card-title class="text-center justify-start py-6">
                <h1 class="font-weight-bold text-h2 basil--text
                ">
                    BASiL
                </h1>
            </v-card-title> 
        </v-card>

    </div>
  </template>
    <script>
    export default {
      data () {
        return {
          selection: [],
          showTableOfContents: false,
        }
      },
      methods: {
        onClickOutsideTableOfContents () {
          this.showTableOfContents = false
        },
      }
    }
  </script>
  <style>
  /* Helper classes */
  .basil {
    background-color: #FFFBE6 !important;
  }
  .basil--text {
    color: #356859 !important;
  }

.svg-border-waves .v-image {
  position: absolute;
  bottom: -50px;
  left: 0;
  height: 5rem;
  width: 100%;
  overflow: hidden;
}
.border-radius {
  border-radius: 10px 10px 10px 10px !important;
}
</style>