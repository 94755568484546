import { render, staticRenderFns } from "./ReplyCommentCard.vue?vue&type=template&id=0eeeca62&scoped=true&"
import script from "./ReplyCommentCard.vue?vue&type=script&lang=ts&"
export * from "./ReplyCommentCard.vue?vue&type=script&lang=ts&"
import style0 from "./ReplyCommentCard.vue?vue&type=style&index=0&id=0eeeca62&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0eeeca62",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VCard,VCardActions,VImg,VListItem,VListItemAvatar,VListItemContent,VListItemSubtitle,VSpacer,VTextarea})
