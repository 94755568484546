import Vue from "vue";
import VueRouter, {RouteConfig} from "vue-router";
import Chats from "../views/Chats.vue";
import Feed from "../views/Feed.vue";
import Communities from "../views/Communities.vue";
import Chat from "@/views/Chat.vue";
import CommunityChat from "@/views/CommunityChat.vue";
import CommunityHub from "@/views/CommunityHub.vue";
import Event from "@/components/communityHub/content/Event.vue";
import Course from "@/components/communityHub/content/Course.vue";
import Profile from "@/components/communityHub/content/Profile.vue";
import Thread from "@/components/Thread.vue";
import ChatHome from "@/layouts/ChatHome.vue";
import Welcome from "@/views/Welcome.vue";
import SignUp from "@/views/SignUp.vue";
import SignIn from "@/views/SignIn.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path     : '/',
        name     : 'Welcome',
        component: Welcome
    },
    {
        path     : '/signup/:communityId?',
        name     : 'Signup',
        component: SignUp
    },
    {
        path     : '/signin',
        name     : 'Signin',
        component: SignIn
    },    
    {
        path     : '/home',
        name     : 'ChatHome',
        component: ChatHome,
        children : [
            {
                path     : 'chats',
                name     : 'Chats',
                component: Chats
            },
            {
                path     : 'updates',
                name     : 'Updates',
                component: Feed
            },
            {
                path     : 'communities/:status?',
                name     : 'Communities',
                component: Communities
            }
        ]
    },
    {
        path     : '/home/chats/:id/count/:count',
        name     : 'Chat',
        component: Chat,
    },
    {
        path     : '/home/communityChats/:id/count/:count',
        name     : 'CommunityChat',
        component: CommunityChat,
    },
    {
        path     : '/home/hub/:id/event/:eventId',
        name     : 'Event',
        component: Event,
    },
    {
        path     : '/home/hub/:id/course/:courseId',
        name     : 'Course',
        component: Course,
    },
    {
        path     : '/home/hub/:id/profile/:memberId',
        name     : 'Profile',
        component: Profile,
    },
    {
        path     : '/home/hub/:id',
        name     : 'CommunityHub',
        component: CommunityHub,
    },
    {
        path     : '/home/hub/:id/thread/:threadId',
        name     : 'Thread',
        component: Thread,
    }
]

const router = new VueRouter(
    {
        mode: 'history',
        base: process.env.BASE_URL,
        routes
    })
    // router.beforeEach((to, from, next) => {
  
    //     document.title = `${to.name} - ${process.env.VUE_APP_TITLE}`
    //     next()
    //   })
export default router;
